import React from "react";
import BlockTitle from "../block-title";

const HeroHeader = ({ tagLine1, title1, desc }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="leading-tight md:w-1/2">
        <BlockTitle title={tagLine1} tagLine={title1} />
      </div>

      <div className="text-justify md:w-1/2 align-text-bottom">{desc}</div>
    </div>
  );
};

export default HeroHeader;
