import React from "react";
import { Link } from "gatsby";

const BlogCard = ({ image, title, date, link }) => {
  return (
    <div className="blog-card bg-gray-50 hover:bg-white">
      <div className="blog-card__inner">
        <div className="blog-card__image">
          <img src={image} alt="" />
          <div className="blog-card__date">{date}</div>
        </div>
        <div className="blog-card__content">
          <div className="blog-card__meta "></div>
          <h3 className="leading-snug">
            <Link to={link}>{title}</Link>
          </h3>

          <Link className="blog-card__more" to={link}>
            <i className="far fa-angle-right"></i>Read More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
